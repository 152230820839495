.nav {
    background-color: #62774C;
    height: 15%;
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10%;
    padding-left: 15%;
    font-size: 110%;
}

.nav h3 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    color: #ffffff;
}

.nav h3:hover {
    color: #000000;
}

.nav a {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    color: #ffffff;
    text-decoration: none;
}

.nav a:hover {
    color: #000000;
}