.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%
}

.titleWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 12%;
    width: 75%;
}

.titleWrapper h1 {
    font-size: 7vh;
    margin-bottom: 0px;
    font-family: 'Playfair Display', serif;
    color: #454545;
    text-align: center;
}

.titleWrapper h2 {
    font-size: 4vh;
    margin-top: 10px;
    color: #62774C;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    text-align: center;
}

.home p {
    font-size: 3vh;
    width: 75%;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: #454545;
}

.home p a {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    text-decoration: none;
    color: #62774C;
}

.home p a:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 1200px) {
    .titleWrapper h1 {
        font-size: 4vh;
    }

    .titleWrapper h2 {
        font-size: 2.5vh;
    }

    .home p {
        font-size: 2vh;
    }
}

@media only screen and (max-width: 1200px) {
    .titleWrapper h2 {
        font-size: 2.5vh;
    }
}